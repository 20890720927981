import merge from "deepmerge"
import defaultTheme from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/components"

const whiteGreen = "#E8F5E9"
const blueGray = "#282c35"
const teal = "#009688"

export default merge(defaultTheme, {
  colors: {
    text: blueGray,
    primary: teal,
    heading: blueGray,
    background: whiteGreen,
    muted: "lightGray",
    modes: {
      dark: {
        background: "black",
        primary: teal,
        secondary: "white",
        highlight: blueGray
      }
    }
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif', // eslint-disable-line
    heading: "Inter, sans-serif",
    monospace: "Menlo, monospace"
  },
  badges: {
    tag: {
      backgroundColor: "primary",
      color: "background",
      padding: "4px 8px",
      borderRadius: "4px",
      fontSize: 1,
      fontWeight: "normal",
      textTransform: "lowercase",
      "&:hover": {
        backgroundColor: "secondary",
        transition: "background-color 0.2s"
      }
    }
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body"
    },
    h1: {
      variant: "text.heading",
      fontSize: 5
    },
    h2: {
      variant: "text.heading",
      fontSize: 4
    },
    h3: {
      variant: "text.heading",
      fontSize: 3
    },
    h4: {
      variant: "text.heading",
      fontSize: 2
    },
    h5: {
      variant: "text.heading",
      fontSize: 1
    },
    h6: {
      variant: "text.heading",
      fontSize: 0
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit"
      }
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit"
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid"
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid"
    },
    blockquote: {
      padding: "16px",
      backgroundColor: "highlight",
      borderRadius: "4px",
      borderLeft: (t) => `8px solid ${t.colors.primary}`,
      color: (t) => t.colors.accent
    }
  }
})
